import { template as template_09ad551641804bdd812e59272ec70d12 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_09ad551641804bdd812e59272ec70d12(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
