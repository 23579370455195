import { template as template_cbbeb65bc16746c3bbd5fc5aa6016fef } from "@ember/template-compiler";
const FKControlMenuContainer = template_cbbeb65bc16746c3bbd5fc5aa6016fef(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
