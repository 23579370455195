import { template as template_9a87f88853f74ff3b68bf6cd49136fc6 } from "@ember/template-compiler";
const FKLabel = template_9a87f88853f74ff3b68bf6cd49136fc6(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
